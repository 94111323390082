body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
  color: #e0e0e0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: 'Courier New', monospace;
  padding: 20px;
  margin: 0 auto;
}

.command-tree {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
  overflow-x: auto;
}

.error {
  color: #ff6b6b;
  font-weight: bold;
  padding: 10px;
  background-color: #3a2a2a;
  border-radius: 5px;
  margin-bottom: 20px;
}

.loading {
  font-style: italic;
  color: #888;
  text-align: center;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #e0e0e0;
  margin-bottom: 30px;
}

.flag {
  color: #4dabf7;
  font-style: italic;
}

ul {
  list-style-type: none;
  padding-left: 20px;
}

strong {
  color: #fff;
}

.command-item {
  margin-bottom: 10px;
}

.command-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.command-name {
  margin-left: 10px;
}

.command-details {
  margin-left: 20px;
  padding-left: 10px;
  border-left: 1px solid #555;
}

.flag-item {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.flag-text {
  margin-left: 10px;
  font-size: 14px;
}